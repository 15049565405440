import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import BackgroundImg from 'gatsby-background-image';

import styled from 'styled-components';

const SaxBackground = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "man_saxaphone.jpg"}) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImg
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={"#000000"}
        />
      )
    }}
  />
)

const styleSaxBackground = styled(SaxBackground)`
  position: relative;
  opacity: 0.9;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 400px;
  background-attachment: fixed;
`

export default styleSaxBackground;
