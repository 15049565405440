import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { Link } from 'gatsby';

import Owners from '../components/OwnerImg'

import '../components/test.css'
import { Typography } from '@material-ui/core'

import StyleBackgroundImage from '../components/background'
import StyledCalcBackground from '../components/calc_background2'
import StyledSaxBackground from '../components/saxaphoneBackground'

import GetMoney from '../components/coinHandIcon';
import TaxFile from '../components/taxFileIcon';
import TaxRepair from '../components/taxRepairIcon';
import BuildBuisness from '../components/buildBuisnessIcon';
import MobileService from '../components/mobileServices';
import Qvc from '../components/qvc_code';

const useStyles = makeStyles(theme => ({
  alignStuff: {
    textAlign: 'left'
  },
  button: {
    display: 'block',
    marginRight: 'auto',
    marginTop: '30px'
  },
  section1: {
    marginBottom: '10px'
  },
  serviceItem: {
    marginBottom: '10px'
  },
  serviceName: {
    marginTop: '15px'
  },
  section3text: {
    width: '80%',
    margin: '30px auto'
  }
}))

const SecondPage = () => {
  const classes = useStyles();
  return (
    <Layout>
      <SEO title="Home"/>
      <div className="app">
        <Hidden smDown>
          <StyleBackgroundImage/>
          {/*<div className="pimg1">*/}
          {/*  <div ptext>*/}
          {/*    <span className="border"></span>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </Hidden>
        <section className="section-one section-light">
          <Grid container>
            <Grid item xs={12}>
              <Grid container justify={'space-between'}>
                <Grid item xs={12} sm={12} md={12} lg={6} className={classes.section1}>
                  <Owners/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5} align={'center'} className={classes.section1}>
                  <div style={{width: '100px', marginBottom: '15px'}}>
                    <GetMoney/>
                  </div>

                  <h2 style={{marginTop: '40px', fontFamily: "Shadows Into Light", color: "#ff0000"}}>Where we work hard to get you your tax dollars!!</h2>
                  <Typography variant={'body1'} component={'div'} className={classes.alignStuff}>
                    Here at Gray's Tax Express, we provide Federal and Personal
                    Tax returns for each client. We prepare personal,
                    small business, and self employed tax returns. Our team is
                    passionate about the services provided, as a result of
                    working with hard working individuals, we understand the
                    importance of your tax dollars and dealing with the IRS.
                    Gray's Tax Express also offers Credit Repair and Business
                    Credit building services. Our company offer income tax
                    preparation to all 50 states, simply download our mobile
                    app, email, or fax over your documentation and one of our
                    tax professionals will get right to work. If you have any
                    questions please give us a call and we will be happy to
                    assist you! Thank you for choosing us here at Gray's Tax
                    Express as your tax professionals!
                  </Typography>
                  <Link to="/services">
                    <Button variant="contained" className={classes.button}>
                      Learn More
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*<h2>Section One</h2>*/}
          {/*<p>*/}
          {/*  Lorem Ipsum is simply dummy text of the printing and typesetting*/}
          {/*  industry. Lorem Ipsum has been the industry's standard dummy text*/}
          {/*  ever since the 1500s, when an unknown printer took a galley of type*/}
          {/*  and scrambled it to make a type specimen book. It has survived not*/}
          {/*  only five centuries, but also the leap into electronic typesetting,*/}
          {/*  remaining essentially unchanged. It was popularised in the 1960s*/}
          {/*  with the release of Letraset sheets containing Lorem Ipsum passages,*/}
          {/*  and more recently with desktop publishing software like Aldus*/}
          {/*  PageMaker including versions of Lorem Ipsum.*/}
          {/*</p>*/}
        </section>
        <StyledCalcBackground/>
        {/*<div className="pimg2">*/}
        {/*  <div ptext>*/}
        {/*    <span className="border"></span>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <section className="section-two section-light">
          <Grid container justify={'center'}>
            <Grid item xs={12}>
              <h1 style={
                { fontFamily: "Shadows Into Light",
                  color: "#ff0000",
                  textDecoration: "underline",
                  marginBottom: '50px'
                }}>SERVICES</h1>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify={'space-evenly'}>
                <Grid item xs={12} sm={6} md={6} lg={3} className={classes.serviceItem}>
                  <Grid container justify={"center"}>
                    <Grid item xs={2} lg={3}>
                      <TaxFile/>
                    </Grid>
                    <Grid item xs={10} lg={12}>
                      <h3 className={classes.serviceName}>Tax Preparation</h3>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} className={classes.serviceItem}>
                  <Grid container justify={"center"}>
                    <Grid item xs={2} lg={3}>
                      <TaxRepair/>
                    </Grid>
                    <Grid item xs={10} lg={12}>
                      <h3 className={classes.serviceName}>Business LLC Prep</h3>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} className={classes.serviceItem}>
                  <Grid container justify={"center"}>
                    <Grid item xs={2} lg={3}>
                      <BuildBuisness/>
                    </Grid>
                    <Grid item xs={10} lg={12}>
                      <h3 className={classes.serviceName}>Building Buisness Credit</h3>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} className={classes.serviceItem}>
                  <Grid container justify={"center"}>
                    <Grid item xs={2} lg={3}>
                      <MobileService/>
                    </Grid>
                    <Grid item xs={10} lg={12}>
                      <h3 className={classes.serviceName}>Mobile Services</h3>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={109} lg={8} align={"center"}>
              <h3 style={{marginTop: '40px', fontFamily: "Shadows Into Light", color: "#ff0000"}}>Check out the other services we provide for our customers. Our
                goal is to help you take control of your finances now and into
                the future.
              </h3>
              <Link to="/services">
                <Button variant="contained" className={classes.serviceName}>
                  Learn More
                </Button>
              </Link>
            </Grid>
          </Grid>
        </section>
        <StyledSaxBackground/>
        {/*<div className="pimg3">*/}
        {/*  <div ptext>*/}
        {/*    <span className="border"></span>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <section className="section-three section-light">
          <Grid container>
            <Grid item xs={12} lg={6}>
              <h1 style={{marginTop: '40px', fontFamily: "Shadows Into Light", textDecoration: "underline", color: "#ff0000"}}>LET'S GET STARTED...</h1>
              <Typography variant={'body1'} component={'div'} className={classes.section3text}>
                Upload and submit your W2s and other supporting documents within
                a matter a minutes -- and all is a secure environment that
                protects your important data. Scan the qr-code or download the
                Tax Pass app to get started.
              </Typography>
              <a
                href={"https://apps.apple.com/us/app/taxpass/id1256036789"}
                style={{marginTop: '30px', fontFamily: "Shadows Into Light", color: "#ff0000", fontSize: '32px', display: 'block', cursor: 'pointer'}}
              >
                Download Tax Pass
              </a>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div style={{width: '60%', margin: '0px auto'}}>
               <Qvc/>
              </div>
            </Grid>
          </Grid>
        </section>
        <StyleBackgroundImage/>
        {/*<div className="pimg1">*/}
        {/*  <div ptext>*/}
        {/*    <span className="border"></span>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </Layout>
  )
}

export default SecondPage
