import React from 'react';
import { StaticQuery, graphql} from 'gatsby';
import styled from 'styled-components';

import BackgroundImage from 'gatsby-background-image';

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "background_logo.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#000000`}
        />
      )
    }}
  />
)

const StyleBackgroundImage = styled(BackgroundSection)`
    position: relative;
    opacity: 0.9;
    background-position: center;
    background-size: auto 120%;
    background-repeat: no-repeat;
    
    min-height: 700px;
    background-attachment: fixed;
`

export default StyleBackgroundImage;
